@import "./spacing.scss";

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.no-underline {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.align-end {
  align-self: end;
}
