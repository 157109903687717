@import "./themes/styles/globalStyles.scss";

// Required For Toolbar icons
svg {
  width: 1rem;
  // height: 1rem;
  fill: currentColor;
  display: block;
}

.text-capitalize {
  text-transform: capitalize;
}
.d-flex {
  display: flex !important;
  flex-wrap: wrap !important;
}
.valign-middle {
  vertical-align: middle;
}

.xt--numcard {
  padding: 10px;
  border: 1px solid transparent;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  margin: 1px;
}
.xr-embedded-page-container,
.xr-embedded-page-content {
  width: 100%;
  height: 100%;
}
.xr-embedded-page-content {
  flex-direction: column;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  flex: 1 1 0%;
}
